<template>
    <section class="content">
        <table class="table table-hover table-striped table-no-border" ref="tablesuswa" id="tb_vsspec" style="width: 100%;text-align: center;">
            <thead>
                <tr>
                    <th rowspan="2" style="text-align: center;">No</th>
                    <th rowspan="2" style="text-align: center;">Nama Siswa</th>
                    <th rowspan="2" style="text-align: center;">Program</th>
                    <th colspan="4" style="text-align: center;">Try out POLRI</th>
                    <th rowspan="2" style="text-align: center;">Skor Akhir (Skor x Bobot)</th>
                </tr>
                <tr>
                    <th style="text-align: center;">WK</th>
                    <th style="text-align: center;">MTK</th>
                    <th style="text-align: center;">BING</th>
                    <th style="text-align: center;">PU</th>
                    <th style="text-align: center;">WK (Tanggal)</th>
                    <th style="text-align: center;">WK (Skor)</th>
                    <th style="text-align: center;">MTK (Tanggal)</th>
                    <th style="text-align: center;">MTK (Skor)</th>
                    <th style="text-align: center;">BING (Tanggal)</th>
                    <th style="text-align: center;">BING (Skor)</th>
                    <th style="text-align: center;">PU (Tanggal)</th>
                    <th style="text-align: center;">PU (Skor)</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import moment from "moment";
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            filter: {},
            siswa: {},
            method: '',
            roles: '',
            id: '',
            branch: '',
            userid: '',
            formTitle: ''
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
            if (e.target.matches(".pembahasan")) {
                this.$router.push({ path: "/ujian-finish/" + e.target.dataset.id });
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesuswa, {
            "title": "Rapor Evaluasi",
            "roles": ["read"],
            "ajax": "/siswa/ujian/ranking_web_polri",
            "columns": [
                { "data": "nomor" },
                { "data": "nama_siswa",},
                { "data": "program_name" },
                { "data": "twk" },
                { "data": "mat" },
                { "data": "bing" },
                { "data": "pu" },
                { "data": "all_score" },
                { "data": "twk_dt", "visible": false },
                { "data": "twk_val", "visible": false },
                { "data": "mat_dt", "visible": false },
                { "data": "mat_val", "visible": false },
                { "data": "bing_dt", "visible": false },
                { "data": "bing_val", "visible": false },
                { "data": "pu_dt", "visible": false },
                { "data": "pu_val", "visible": false },
            ],
            aLengthMenu: [
                [10 ,25, 50, 100, 200, 300, -1],
                [10 ,25, 50, 100, 200, 300, 'ALL']
            ],
            filterBy: [1],
            scrollX: true,
            paging: this.$route.params.kelas_id == 0 ? true : false,
            paramData: function(d) {
                d.mapel_id = self.$route.params.mapel_id;
                d.paket_id = self.$route.params.paket_id;
                d.program_id = self.$route.params.program_id;
                d.kelas_id = self.$route.params.kelas_id;
            },
            rowCallback: function (row, data) {
                // $('td:eq(0)', row).attr('rowspan', 3);
            },
            initComplete: function() {
                authFetch("/siswa/ujian/ujian_polri_info/" + self.$route.params.paket_id).then((res) => {
                    res.json().then((json) => {
                      self.dataUjian = json;
                      self.formTitle = json.data.nama_paket;
                      $('.card-title').html(json.data.nama_paket)
                    });
                  });
            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
              {
                  extend: 'excel',
                  text: 'XLSX',
                  className: 'btn btn-success',
                  exportOptions: {
                      columns:[0,1,2,8,9,10,11,12,13,14,15,7],
                  },
                  title: function(){
                      return self.formTitle;
                  },
                  filename: function(){
                      return self.formTitle;
                  },
              },
              {
                  extend: 'print',
                  text: 'Print',
                  className: 'btn btn-primary',
                  exportOptions: {
                      columns:[0,1,2,3,4,5,6,7],
                  },
                  title: function(){
                      return self.formTitle;
                  },
                  filename: function(){
                      return self.formTitle;
                  },
              },
              {
                  extend: 'pdf',
                  text: 'PDF',
                  className: 'btn btn-info',
                  exportOptions: {
                      columns:[0,1,2,3,4,5,6,7],
                  },
                  title: function(){
                      return self.formTitle;
                  },
                  filename: function(){
                      return self.formTitle;
                  },
              },
              {
                  extend: 'csvHtml5',
                  text: 'CSV',
                  className: 'btn bg-gray-dark',
                  exportOptions: {
                      columns:[0,1,2,3,4,5,6,7],
                  },
                  title: function(){
                      return self.formTitle;
                  },
                  filename: function(){
                      return self.formTitle;
                  },
              },
            ],
        });
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .table thead th {
        border-bottom:  none;
    }
    .table td, .table th {
        border-top:  none;   
    }
    .table td {
        vertical-align: middle;
    }
    .bdge-info {
        color: #fff;
        background-color: #7C99AC !important;
    }

    .bdge {
        display: inline-block;
        padding: .25em .4em;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .4rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
</style>